import Header from "./Header";
import LeftMenu from "./LeftMenu";

export default function DefaultLayout({children}){
    return (
        <div>
            <Header />
            <div className="main-body">
            <LeftMenu />
            {children}
            </div>
        </div>
    )
}