import { useState } from 'react';
import { Button, Form, Row , Container} from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import http from '../../http'

export default function Register() {
    const navigate = useNavigate();
    const [inputs, setInputs] = useState({});
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }))
    }
    const submitForm = (event) => {
        event.preventDefault();
        http.post('api/auth/register', inputs).then((res) => {
            alert("Bạn đã đăng ký thành công!");
            navigate('/login');
        }).catch((error) => {
          });
         
    }
    return (
        <Container className='mt-3'>
            <Form onSubmit={submitForm} className='app-form-login'>
                <Row className="mb-3">
                    <Form.Group md="6" controlId="validationCustom01">
                        <Form.Label>Tên đăng nhập </Form.Label>
                        <Form.Control
                            name="name"
                            onChange={handleChange}
                            type="text"
                            placeholder="Nhập tên đăng nhập"
                        />
                    </Form.Group>
                </Row>
              
                <Row className="mb-3">
                    <Form.Group md="6" controlId="validationCustom02">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            onChange={handleChange}
                            type="text"
                            placeholder="Nhập Email"
                            name="email"
                        />
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group md="6" controlId="validationCustomUsername">
                        <Form.Label>Mật khẩu</Form.Label>
                        <Form.Control
                            name="password"
                            type="password"
                            placeholder="Nhập mật khẩu"
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Row>
                <Button type="submit">Đăng ký</Button>
            </Form>
        </Container>
    );
}