export default function Header(){
    return (
        <nav className='header'>
          <div className="logo-header">
            <div className="div-logo">
              <span className="bigin-logo"></span>
              <span className="bigin-logo-text">Bigin</span>
            </div>
          </div>
          <div className="search-header">
            <div className="search-select">
              <span>Tất cả</span>
              <span className="icon-ct icon-select"></span>
            </div>
              <input className="search-input" placeholder="Tìm kiếm (ctrl+k)"></input>
          </div>
          <div className="info-header">
            <span className="ms-4 me-4 ellipsis">Còn lại 13 ngày trong thời gian dùng thử Premier của bạn. Nâng cấp</span>
            <span className="ms-4 me-4 icon-ct icon-h-plus"></span>
            <span className="ms-4 me-4 icon-ct icon-h-notify"></span>
            <span className="ms-4 me-4 icon-ct icon-h-war"></span>
            <span className="ms-4 me-4 icon-ct icon-h-setting"></span>
            <span className="ms-4 me-4 icon-ava">KN</span>
          </div>
        </nav>
    )
}