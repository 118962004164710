


import { useState } from 'react';
import { Button, Form, Tabs, Tab, Dropdown } from 'react-bootstrap';
import dataStore from '../../DataStore';
import { observer } from 'mobx-react';

function Contact() {
    const [inputs, setInputs] = useState({});
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }))
    }
    const submitForm = (event) => {
        event.preventDefault()
        dataStore.updateState(inputs);
    }
    return (
        <div className="component-content">
            <div className="row header-component header-contact mg-0">
                <div className="col-md-6 pd-0 d-flex justify-content-start">
                    <span className="icon-ct  icon-h-arrleft"></span>
                    <div className="user-info d-flex">
                        <div className="user-info-av">TW</div>
                        <div className="info">
                            <div className="position-relative user-i-name hover-element">
                                Ted Watson
                                <span className="icon-ct icon-h-edit icon-edit-u"></span>
                                <span className="h-ins">
                                    <span className="icon-ct  icon-h-ins">

                                    </span>
                                </span>

                            </div>
                            <div className="user-info-name hover-element">
                                <span>Zylker Corp</span>
                                <span className="icon-ct icon-h-wrapper "></span>
                                <span>
                                    <span className="icon-ct  icon-h-star"></span>
                                    Khánh Ngọc
                                </span>
                                <span className="icon-ct icon-h-edit icon-edit-u"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 pd-0 d-flex justify-content-end align-items-center">
                    <div className="me-3">
                        <button className="btn btn-contact btn-send-m me-3"><span className="icon-ct icon-emai"></span> Gửi Mail</button>
                        <button className="btn btn-ct me-3"><span className="icon-ct  icon-h-edit"></span></button>
                        <button className="btn btn-ct"><span className="icon-ct  icon-h-more"></span></button>
                    </div>
                    <div className="me-3">
                        <button className="btn  btn-ct-tb active"><span className="icon-ct  icon-h-r"></span></button>
                        <button className="btn btn-ct-b"><span className="icon-ct  icon-h-l"></span></button>
                    </div>
                </div>
            </div>
            <div className="contact-main">
                <div className="contact-main-left">
                    <div className="contact-tk div-contact">
                        <div className="contact-title">Thống kê</div>
                        <div className="contact-tk-text">
                            <span className="stats-bullet-blue me-3"></span>
                            <span className="me-3">Mở Bản Ghi Hệ Thống</span>
                            <span className="text-dot me-3"></span>
                            <span className="text-inf ellipsis me-3 fb">1</span>
                        </div>
                    </div>
                    <div className="contact-base div-contact">
                        <div className="contact-title">Thông tin cơ bản</div>
                        <div className="contact-base-item hover-element">
                            <span className="contact-base-item-icon icon-ct icon-emai"></span>
                            <span className="contact-base-item-text">support@bigin.com</span>
                            <span className="icon-ct icon-h-edit icon-edit-u"></span>
                        </div>
                        <div className="contact-base-item hover-element">
                            <span className="contact-base-item-icon  icon-ct icon-phone"></span>
                            <span className="contact-base-item-text">609-884-0686</span>
                            <span className="icon-ct icon-h-edit icon-edit-u"></span>
                        </div>
                        <div className="contact-base-item hover-element">
                            <span className="contact-base-item-icon  icon-ct icon-mobile"></span>
                            <span className="contact-base-item-text">--</span>
                            <span className="icon-ct icon-h-edit icon-edit-u"></span>
                        </div>
                        <div className="contact-base-item hover-element">
                            <span className="contact-base-item-icon  icon-ct icon-mobile-"></span>
                            <span className="contact-base-item-text">--</span>
                            <span className="icon-ct icon-h-edit icon-edit-u"></span>
                        </div>
                        <div className="contact-base-item hover-element">
                            <span className="contact-base-item-icon  icon-ct icon-address"></span>
                            <span className="contact-base-item-text">609-884-0686</span>
                            <span className="icon-ct icon-h-edit icon-edit-u"></span>
                        </div>
                    </div>
                    <div className="contact-tag div-contact">
                        <div className="contact-title">Tag</div>
                        <span className="tag"><span className="icon-ct icon-plus"></span> Tag</span>
                    </div>
                    <div className="contact-des div-contact hover-element">
                        <div className="contact-title">Mô tả</div>
                        <div className="contact-des-text">
                            <p>Đây là một Liên hệ mẫu.</p>
                            <p>✷ Nhanh chóng bắt đầu bằng cách tạo một ghi chú ✍️hoặc một hoạt động ⏰ cho Liên hệ này.</p>
                            <p>✷ Để được trợ giúp hãy gửi ... Hiển thị thêm</p>
                        </div>
                    </div>
                    <div className="contact-inf-other div-contact">
                        <div className="contact-title">Thông tin khác</div>
                        <div className="contact-tk-text hover-element">
                            <p >Không nhận Email</p>
                            <p className="text-inf ellipsis">Điều Chỉnh Lần Cuối vào Tháng 5 10, 10:36 Chiều/tối</p>
                        </div>
                    </div>
                </div>
                <div className="contact-main-right position-relative">
                    <Tabs defaultActiveKey="time" id="uncontrolled-tab-example" className="nav-ct position-relative " >
                        <Tab eventKey="time" title="Thời gian biểu" className='tab-content-ct  h-100'>
                            <div className='tab-pane h-100'>
                                <div className="time-tab-title d-flex justify-content-between">
                                    <span className="fb lh-2">Lịch sử</span>
                                    <div className="form-select-ct position-relative me-3">
                                        <label className="me-2">Lọc: </label>
                                        <select className="header-component-select w-i hover-border" >
                                            <option>Tất cả</option>
                                        </select>
                                        <span className="icon-ct icon-select icon-select-ct"></span>
                                    </div>
                                </div>
                                <div className="time-content pt-5">
                                    <p className="text-center time-empty-state ">Form test</p>
                                    <Form onSubmit={submitForm} className='app-form-login' style={{ 'minHeight': "auto" }}>
                                        <Form.Group md="4" className="mb-3" controlId="validationCustom01">
                                            <Form.Label>Tên</Form.Label>
                                            <Form.Control
                                                name="name"
                                                onChange={handleChange}
                                                type="text"
                                                placeholder="Nhập tên"
                                            />
                                        </Form.Group>
                                        <Form.Group md="4" className="mb-3" controlId="validationCustomUsername">
                                            <Form.Label>Số điện thoại</Form.Label>
                                            <Form.Control
                                                name="phone"
                                                type="number"
                                                placeholder="Nhập số điện thoại"
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                        <Button type="submit">Submit</Button>
                                    </Form>
                                </div>
                            </div>

                        </Tab>
                        <Tab eventKey="note" title="Ghi chú" className='tab-content-ct h-100'>
                            <div className='h-100 tab-pane'>
                                <div className="time-tab-title d-flex justify-content-between">
                                    Ghi chú
                                </div>
                                <span className="note-count">1</span>
                            </div>
                        </Tab>
                        <Tab eventKey="active" title="Hoạt động" className='tab-content-ct h-100'>
                            <div className='tab-pane h-100'>
                                <div className="time-tab-title d-flex justify-content-between">
                                    Hoạt động
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="email" title="Email" className='tab-content-ct h-100'>
                            <div className='tab-pane h-100'>
                                <div className="time-tab-title d-flex justify-content-between">
                                    Email
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="system" title="Hệ thống" className='tab-content-ct h-100'>
                            <div className='tab-pane h-100'>
                                <div className="time-tab-title d-flex justify-content-between">
                                    Hệ thống
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="file" title="Tệp tin" className='tab-content-ct h-100'>
                            <div className='tab-pane h-100'>
                                <div className="time-tab-title d-flex justify-content-between">
                                    Tệp tin
                                </div>
                            </div>
                        </Tab>

                    </Tabs>
                    <span className="oder-tab">
                        <span className="icon-ct icon-order-tab"></span>
                    </span>
                    <div className="nav-ct-res">
                        <Dropdown>
                            <Dropdown.Toggle variant="success" className='btn btn-ct' id="dropdown-basic">
                            <span className="icon-ct  icon-h-more"></span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="#">Thời gian biểu</Dropdown.Item>
                                <Dropdown.Item href="#">Ghi chú</Dropdown.Item>
                                <Dropdown.Item href="#">Hoạt động</Dropdown.Item>
                                <Dropdown.Item href="#">Email</Dropdown.Item>
                                <Dropdown.Item href="#">Xã hội</Dropdown.Item>
                                <Dropdown.Item href="#">Hệ thống</Dropdown.Item>
                                <Dropdown.Item href="#">Tệp tin</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>
        </div>

    );
}
export default observer(Contact);