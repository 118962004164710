
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { Authentication } from '../../../../Authentication';

export default function LeftMenu() {
  const navigate = useNavigate();
  const [active, setActive] = useState();
  const handleClick = (menuItem) => { 
    setActive(menuItem);
  }
  const handleLogout = ()=>{
    localStorage.removeItem('accessToken')
    navigate('/login')
    window.location.reload()
  }
  return (
    <div className='left-menu'>
      <ul className="left-menu-ul">
        <li className={`li-item ${active === 'system' && 'active'}`}
       >
          <Link  onClick={() => handleClick("system")} to={"/system"} className="nav-link"><span className="icon-ct icon-ht"></span>
            Hệ thống</Link>
            
        </li>
        <li className={`li-item ${active === 'contact' && 'active'}`}   >
          <Link to={"/contact"} className="nav-link"  onClick={() => handleClick("contact")}>  <span className="icon-ct icon-contact"></span>
            Liên hệ</Link>
        </li>
        {!Authentication() && <li className="li-item">
          <Link to={"/register"} className="nav-link"><span className="icon-ct icon-contact"></span>
            Đăng ký</Link></li>
        }
        {!Authentication() && <li className="li-item">
          <Link to={"/login"} className="nav-link"><span className="icon-ct icon-contact"></span>
            Đăng nhập</Link></li>
        }
        {Authentication() && <li className="li-item">
          <Link onClick={()=> handleLogout()}  className="nav-link"><span className="icon-ct icon-contact"></span>
            Đăng xuất</Link></li>
        }
      </ul>
    </div>
  )
}