import "./css/style.css"
import "./css/response.css"
import "./css/icon.css"
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { Authentication } from './Authentication';

import { Routes, Route, Outlet, Navigate} from "react-router-dom";
import { DefaultLayout } from './components/Layout'
import { publicRoutes,privateRoutes } from "./routes";
function App() {
  const Layout = DefaultLayout
  return (
        <Routes>
          <Route path='/' element={<Private />} >
            {privateRoutes.map((route,index) => {
              const Page = route.component
              return <Route key={index} path={route.path} element={
                <Layout>
                  <Page />
                </Layout>
              } />
            })}
          </Route>
          {publicRoutes.map((route,index) => {
            const Page = route.component
            return <Route key={index} path={route.path} element={<Layout>
              <Page />
            </Layout>} />
          })}
        </Routes>
  );
}
const Private = () => {
  const isAuthenticated = Authentication();
  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
}
export default App;
