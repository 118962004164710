import { observable, action } from 'mobx';

class DataStore {
  myState = observable.box({});

  updateState = action((value) => {
    this.myState.set(value);
  });
}

const store = new DataStore();
export default store;
