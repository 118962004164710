
import Login from '../pages/login/login'
import Register from '../pages/register/index'
import System from '../pages/system/index'
import Contact from '../pages/contact/index'


const publicRoutes = [
    {path: '/register', component:Register},
    {path: '/login', component:Login},
]
const privateRoutes = [
    {path: '/', component:System},
    {path: '/system', component:System},
    {path: '/contact', component:Contact},

]

export {publicRoutes,privateRoutes}