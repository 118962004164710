import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import http from '../../http'

export default function Login() {
    const navigate = useNavigate();
    const [inputs, setInputs] = useState({});
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }))
    }

    const submitForm = (event) => {
        event.preventDefault();
        http.post('api/auth/login', inputs).then((res) => {
            localStorage.setItem('accessToken',res.data.token)
            navigate('/', { replace: true });
        }).catch((error) => {
          });
    }

    return (
        <Container>
            <Form onSubmit={submitForm} className='app-form-login'>
                <Row className="mb-3">
                    <Form.Group md="4" controlId="validationCustom01">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            name="email"
                            onChange={handleChange}
                            type="text"
                            placeholder="Nhập email"
                        />
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group md="4" controlId="validationCustomUsername">
                        <Form.Label>Mật khẩu</Form.Label>
                        <Form.Control 
                            name="password"
                            type="password"
                            placeholder="Nhập mật khẩu"
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Row>
                <Button type="submit">Đăng nhập</Button>
            </Form>
        </Container>
    );
}