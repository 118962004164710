import React from 'react';
import { observer } from 'mobx-react';
import dataStore from '../../DataStore';
const System = observer(() => {
    const state = JSON.parse(JSON.stringify(dataStore.myState))
    return (
        <div className="component-content d-flex">
            <div className="com-system">
                <div className="con-system-text fb">
                    <div> <span className="icon-ct icon-h-war mb-4"></span>
                        Hệ thống kinh doanh</div>
                </div>
            </div>
            <div className="com-content">
                <div className="row header-component mg-0">
                    <div className="col-md-4 pd-0 hr-2 position-relative">
                        <button className="btn btn-ct me-4"><span className="icon-ct  icon-h-contact"></span></button>
                        <select className="header-component-select hover-border">
                            <option>Tất cả - Liên hệ</option>
                        </select>
                        <span className="icon-ct icon-select icon-select-ct" style={{'left':"180px"}}></span>
                    </div>
                    <div className="col-md-8 pd-0 d-flex hr-2 justify-content-end">
                        <div className="form-select-ct me-3  position-relative">
                            <label className="me-2">Sắp Xếp Theo</label>
                            <select className="header-component-select hover-border">
                                <option>Thời gian tạo</option>
                            </select>
                            <span className="icon-ct icon-select icon-select-ct"></span>
                        </div>
                        <button className="btn btn-ct btn-ct-filter me-3"><span className="icon-ct  icon-h-filter"></span></button>
                        <div className="me-3">
                            <button className="btn btn-ct-tb active"><span className="icon-ct  icon-h-tb"></span></button>
                            <button className="btn btn-ct-b"><span className="icon-ct  icon-h-b"></span></button>
                        </div>
                        <div>
                            <button className="btn btn-contact fb">+ Liên hệ</button>
                            <button className="btn btn-contact-down me-3"><span className="dd-arrow-down-white"></span></button>
                            <button className="btn btn-ct "><span className="icon-ct  icon-h-more"></span></button>
                        </div>
                    </div>
                </div>
                <div className="header-main">
                    <div className="header-main-grid d-flex h-100">
                        <div className="header-main-flex-item position-relative">
                            <div className="flex-item-top" style={{ "--color": "#7eb5f4" }}>
                                <div className="flex-item-top-text">
                                    <div className="flex-item-top-title ellipsis lh-1 fb">Chứng thực</div>
                                    <div className="ellipsis lh-1 f13">
                                        5.671,00 ₫ &nbsp;
                                        <span className="text-dot"></span> &nbsp;
                                        1 Giao dịch
                                    </div>

                                </div>
                                <div className="flex-item-top-icon"></div>
                            </div>
                            <div className="flex-item-bot">
                                <div className="flex-item-bot-cont">
                                    <div className="flex-item-bot-title fb">Đăng ký hàng năm</div>
                                    <div className="flex-item-bot-title f13">Ted Watson</div>
                                    <div className="ellipsis  f13">
                                        5.671,00 ₫ &nbsp;
                                        <span className="text-dot"></span> &nbsp;
                                        Tháng 5 15
                                    </div>
                                </div>
                                <div className="flex-item-bot-cont">
                                    <div className="flex-item-bot-title fb">Thông tin từ Liên hệ</div>
                                    <div className="ellipsis  f13">
                                        <p>Tên: {state.name}</p>
                                        <p>Sđt: {state.phone}</p>
                                    </div>
                                </div>

                            </div>
                            <div className="item-gd">
                                <div className="d-flex">
                                <span>+ Giao dịch</span>
                                <div className="div-icon-exp">
                                    <span className="icon-ct icon-exp">
                                        
                                        </span>
                                </div>
                                </div>
                               
                            </div>
                        </div>
                        <div className="header-main-flex-item position-relative">
                            <div className="flex-item-top" style={{ "--color": "#7eb5f4" }}>
                                <div className="flex-item-top-text">
                                    <div className="flex-item-top-title ellipsis lh-1 fb">Cần Phân tích</div>
                                    <div className="ellipsis lh-1 f13">
                                        0,00 ₫ &nbsp;
                                        <span className="text-dot"></span> &nbsp;
                                        0 Giao dịch
                                    </div>
                                </div>
                                <div className="flex-item-top-icon"></div>
                            </div>

                            <div className="flex-item-bot">
                                <div className="item-empty">Giai đoạn này còn trống</div>
                            </div>
                            <div className="item-gd">
                                <div className="d-flex">
                                <span>+ Giao dịch</span>
                                <div className="div-icon-exp">
                                    <span className="icon-ct icon-exp">
                                        
                                        </span>
                                </div>
                                </div>
                               
                            </div>
                        </div>
                        <div className="header-main-flex-item position-relative">
                            <div className="flex-item-top" style={{ "--color": "#7eb5f4" }}>
                                <div className="flex-item-top-text">
                                    <div className="flex-item-top-title ellipsis lh-1 fb">Gửi dự toán/Giá</div>
                                    <div className="ellipsis lh-1 f13">
                                        0,00 ₫ &nbsp;
                                        <span className="text-dot"></span> &nbsp;
                                        0 Giao dịch
                                    </div>

                                </div>
                                <div className="flex-item-top-icon"></div>
                            </div>
                            <div className="flex-item-bot">
                                <div className="item-empty">Giai đoạn này còn trống</div>
                            </div>
                            <div className="item-gd">
                                <div className="d-flex">
                                <span>+ Giao dịch</span>
                                <div className="div-icon-exp">
                                    <span className="icon-ct icon-exp">
                                        
                                        </span>
                                </div>
                                </div>
                               
                            </div>
                        </div>
                        <div className="header-main-flex-item position-relative">
                            <div className="flex-item-top" style={{ "--color": "#7eb5f4" }}>
                                <div className="flex-item-top-text">
                                    <div className="flex-item-top-title ellipsis lh-1 fb">Thương lượng/Xem lại</div>
                                    <div className="ellipsis lh-1 f13">
                                        0,00 ₫ &nbsp;
                                        <span className="text-dot"></span> &nbsp;
                                        0 Giao dịch
                                    </div>

                                </div>
                                <div className="flex-item-top-icon"></div>
                            </div>
                            <div className="flex-item-bot">
                                <div className="item-empty">Giai đoạn này còn trống</div>
                            </div>
                            <div className="item-gd">
                                <div className="d-flex">
                                <span>+ Giao dịch</span>
                                <div className="div-icon-exp">
                                    <span className="icon-ct icon-exp">
                                        
                                        </span>
                                </div>
                                </div>
                               
                            </div>
                        </div>
                        <div className="header-main-flex-item position-relative">
                            <div className="flex-item-top" style={{ "--color": "#59D5A0" }}>
                                <div className="flex-item-top-text">
                                    <div className="flex-item-top-title ellipsis lh-1 fb">Đã Kết Thúc Thành Công</div>
                                    <div className="ellipsis lh-1 f13">
                                        0,00 ₫ &nbsp;
                                        <span className="text-dot"></span> &nbsp;
                                        0 Giao dịch
                                    </div>

                                </div>
                                <div className="flex-item-top-icon"></div>
                            </div>
                            <div className="flex-item-bot">
                                <div className="item-empty">Giai đoạn này còn trống</div>
                            </div>
                            <div className="item-gd">
                                <div className="d-flex">
                                <span>+ Giao dịch</span>
                                <div className="div-icon-exp">
                                    <span className="icon-ct icon-exp">
                                        
                                        </span>
                                </div>
                                </div>
                               
                            </div>
                        </div>
                        <div className="header-main-flex-item position-relative">
                            <div className="flex-item-top" style={{ "--color": "#FF8888" }}>
                                <div className="flex-item-top-text">
                                    <div className="flex-item-top-title ellipsis lh-1 fb">Bỏ qua kết thúc</div>
                                    <div className="ellipsis lh-1 f13">
                                        0,00 ₫ &nbsp;
                                        <span className="text-dot"></span> &nbsp;
                                        0 Giao dịch
                                    </div>

                                </div>
                                <div className="flex-item-top-icon"></div>
                            </div>
                            <div className="flex-item-bot">
                                <div className="item-empty">Giai đoạn này còn trống</div>
                            </div>
                            <div className="item-gd">
                                <div className="d-flex">
                                <span>+ Giao dịch</span>
                                <div className="div-icon-exp">
                                    <span className="icon-ct icon-exp">
                                        
                                        </span>
                                </div>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
})
export default System;